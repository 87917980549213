'use client'

import { m, LazyMotion, domAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'
type AnimatedTitleProps = {
  text?: string
  isAnimateChar?: boolean
  children?: React.ReactChild
  [x: string]: any
}

export default function AnimatedTitle({
  text = ``,
  isAnimateChar = false,
  children,
  ...props
}: AnimatedTitleProps) {
  const words = text.split(' ')
  const [key, setKey] = useState(0) // Key to trigger re-render

  useEffect(() => {
    setKey((prevKey) => prevKey + 1)
  }, [text])
  const wordVariants = {
    open: {
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.01,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  }

  const charVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.35,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    closed: {
      y: 40,
      opacity: 0,
      transition: {
        duration: 0.35,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  }
  return (
    <>
      <div key={key} className="absolute inline-block" {...props}>
        {words.map((word, wordIndex) => (
          <LazyMotion features={domAnimation} key={wordIndex}>
            <m.h2
              className="mb-4"
              initial={'closed'}
              animate={'open'}
              variants={wordVariants}
              transition={{
                delayChildren: wordIndex * 0.25,
                staggerChildren: 0.05,
              }}
            >
              {Array.from(word).map((char, charIndex) => (
                <m.span
                  key={charIndex}
                  variants={charVariants}
                  className={`inline-block`}
                >
                  {char}
                </m.span>
              ))}
            </m.h2>
          </LazyMotion>
        ))}
      </div>
    </>
  )
}
