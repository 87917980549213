'use client'
import { useState, useEffect, useRef } from 'react'
import { IBM_Plex_Mono } from 'next/font/google'
interface Greeting {
  text: string
  intervalTime?: number
  className?: string
  onHover?: (e: EventListener) => void
  onClick?: () => void
}
const mono = IBM_Plex_Mono({
  subsets: ['latin'],
  weight: ['500'],
  display: 'swap',
})
const Greeting = ({
  text,
  intervalTime = 30,
  className,
  onClick,
}: Greeting): React.ReactElement => {
  const [greeting, setGreeting] = useState(text)
  const letters = 'DANFKJQRPVHCGBZXWUIMSEOLTY!@#$%^&*?'
  const intervalRef = useRef(undefined)

  const handleHover = (event) => {
    const { clearInterval } = window
    let iterations = 0
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
    //@ts-ignore
    intervalRef.current = setInterval(() => {
      setGreeting((prevGreeting) => {
        return prevGreeting
          .split('')
          .map((letter, index) => {
            if (index < iterations) {
              return event.target.dataset.value[index]
            }
            return letters[Math.floor(Math.random() * 35)].toLocaleLowerCase()
          })
          .join('')
      })
      if (
        iterations >= event.target.dataset.value.length &&
        intervalRef.current !== null
      ) {
        clearInterval(intervalRef.current)
      }
      iterations += 1 / event.target.dataset.value.length
    }, intervalTime)
  }

  useEffect(() => {
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <h2 className={`relative ${mono.className}`} onClick={onClick}>
      <span
        className={`${className}`}
        data-value={text}
        onMouseOver={handleHover}
      >
        {greeting}
      </span>
    </h2>
  )
}

export default Greeting
