'use client'

import dynamic from 'next/dynamic'
import { useEffect, useRef } from 'react'
import { useStore } from '@/lib/store'
import { Poppins } from 'next/font/google'
const popins = Poppins({
  subsets: ['latin'],
  weight: ['400', '700'],
  display: 'swap',
})
const Scene = dynamic(() => import('@/components/canvas/Scene'), { ssr: false })

const Layout = ({ children }) => {
  const ref = useRef<HTMLElement | null>(null)
  const fetchProjects = useStore(({ fetchProjects }) => fetchProjects)
  fetchProjects()
  useEffect(() => {
    console.log(
      '%cTam Pham Portfolio ✨',
      'color:#0DD8D8;background: #0B1021; font-size: 2rem; padding: 0.15rem 0.25rem;margin: 1rem; font-family: Helvetica; border: 2px solid #0DD8D8; border-radius: 4px;font-weight: bold; text-shadow: 1px 1px 1px #0DD8D5; font-style: italic; '
    )
  }, [])
  return (
    <div
      // @ts-ignore
      ref={ref}
      className={`relative mx-auto h-full w-full touch-auto overflow-x-hidden ${popins.className}`}
    >
      {children}
      <Scene
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: 'calc(100dvh - 60px)',
          pointerEvents: 'none',
        }}
        eventSource={ref}
        eventPrefix="client"
      />
    </div>
  )
}

export { Layout }
