'use client'
import { extend, useFrame, useLoader, useThree } from '@react-three/fiber'
import { useStore } from 'lib/store'
import { useRouter } from 'next/navigation'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BoxGeometry, RepeatWrapping, TextureLoader } from 'three'
import { useMobileTabletDetect } from '@/hooks/useDeviceType'
import { gsap } from 'gsap/gsap-core'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import '../../material/WaveMaterial'
import { colors } from '@/constant/index'
gsap.registerPlugin(CSSRulePlugin)
extend({ BoxGeometry })

interface WaveProps {
  isScroll?: boolean
  images: any
  slug: string
  imageData: Array<any>
  currentIndex: number
  lastIndex: number
  next: (number) => void
}

function Wave({
  images = [],
  slug = '',
  currentIndex = 0,
  lastIndex = 1,
  next,
}: WaveProps): JSX.Element {
  const ref = useRef<any | null>(null!)
  const refMesh = useRef<any | null>(null!)
  const projectIndex = useStore(({ currentProject }) => currentProject)
  const setStoreHover = useStore(({ setHover }) => setHover)
  const { viewport } = useThree()
  const [scaleFactor, setScaleFactor] = useState(
    Math.min(Math.max(window.innerWidth / 1920, 0.6), 1)
  )
  const maskImg = useLoader(TextureLoader, '/images/shadow.png')
  const isMobile = useMobileTabletDetect()
  maskImg.wrapS = RepeatWrapping
  maskImg.wrapT = RepeatWrapping
  const router = useRouter()
  let cursor = document.getElementById('blob')
  const onSetHover = () => {
    document.body.style.cursor = 'pointer'
    gsap.to(cursor, {
      opacity: 1,
    })
  }
  const onRemoveHover = () => {
    document.body.style.cursor = 'auto'
    gsap.to(cursor, {
      opacity: 0,
    })
  }
  useEffect(() => {
    if (!cursor) return
    window.addEventListener('mousemove', (e) => {
      gsap.to(cursor, {
        duration: 1.2,
        x: e.clientX,
        y: e.clientY,
      })
    })
    gsap.set(cursor, {
      xPercent: -50,
      yPercent: -50,
      opacity: 0,
    })
    return () => {
      document.body.style.cursor = 'auto'
      gsap.set(cursor, {
        xPercent: -50,
        yPercent: -50,
        opacity: 0,
      })
    }
  }, [])

  useEffect(() => {
    ref.current.uProgress = 0
    isMobile
      ? window.addEventListener('touchstart', handleTouchStart)
      : window.addEventListener('wheel', handleWheel)

    return () =>
      isMobile
        ? window.removeEventListener('touchstart', handleTouchStart)
        : window.removeEventListener('wheel', handleWheel)
  }, [projectIndex])

  useLayoutEffect(() => {
    window.resizeBy(0, 0)
    setScaleFactor(
      Math.min(Math.max(viewport.getCurrentViewport().width / 2, 0.6), 1)
    )
  }, [])

  useFrame(({ clock }) => {
    ref.current.uTime = clock.getElapsedTime()
    ref.current.uProgress += (1 - ref.current.uProgress) * 0.1
  })

  const handleViewDetail = (e) => {
    e.stopPropagation()
    setStoreHover(false)
    router.push(`/works/${slug}`)
  }
  const refTime = useRef({
    wheelTimeout: null,
  })
  const speedWheel = 0.1
  const handleWheel = (e) => {
    const delta = e.deltaY
    const progress = ref.current.uProgress + delta * speedWheel
    if (refTime.current.wheelTimeout) {
      clearTimeout(refTime.current.wheelTimeout)
      refTime.current.wheelTimeout = null
    }
    // @ts-ignore
    refTime.current.wheelTimeout = setTimeout(() => {
      if (progress >= 3) {
        next(projectIndex + 1)
        ref.current.uProgress = 0
      } else if (progress <= 0) {
        next(projectIndex - 1)
        ref.current.uProgress = 0
      }
      ref.current.uProgress = 0
    }, 200) // Adjust the delay as needed
  }
  const handleSwipe = (startY, endY) => {
    if (!ref.current) return
    const swipeThreshold = 8 // Adjust the swipe threshold as needed
    const deltaY = endY - startY
    if (deltaY > swipeThreshold) {
      next(projectIndex - 1)
      ref.current.uProgress = 0
    } else if (deltaY < -swipeThreshold) {
      next(projectIndex + 1)
      ref.current.uProgress = 0
    }
  }

  const handleTouchStart = (e) => {
    const touch = e.touches[0]
    const startX = touch.clientX

    const handleTouchMove = (moveEvent) => {
      const touchMove = moveEvent.touches[0]
      const endX = touchMove.clientX
      handleSwipe(startX, endX)
      window.removeEventListener('touchmove', handleTouchMove)
    }

    window.addEventListener('touchmove', handleTouchMove, { once: true })
  }

  return (
    <>
      <group ref={refMesh} dispose={null} scale={scaleFactor}>
        <mesh
          onClick={(e) => handleViewDetail(e)}
          onPointerOver={() => {
            onSetHover()
          }}
          onPointerLeave={() => {
            onRemoveHover()
          }}
          rotation={[-0.25, -0.16, -0.115]}
          position={[0, 0, 0.175]}
        >
          <boxGeometry args={[1.2, 0.7, 0.045, 24, 24, 12]} attach="geometry" />
          <waveShaderMaterial
            ref={ref}
            uTextureOne={images[lastIndex]}
            uTextureTwo={images[currentIndex]}
            uTextureMap={maskImg}
          />
        </mesh>
      </group>
    </>
  )
}

export default Wave
