/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.10 head.glb --transform
Files: head.glb [5.57MB] > head-transformed.glb [507.08KB] (91%)
Author: engine9 (https://sketchfab.com/engine9)
License: CC-BY-SA-4.0 (http://creativecommons.org/licenses/by-sa/4.0/)
Source: https://sketchfab.com/3d-models/rhetorician-a89f035291d843069d73988cc0e25399
Title: Rhetorician
*/
'use client'
import { useIsSmall } from '@/utils'
import { useAnimations, useGLTF } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef } from 'react'
import { Vector3 } from 'three'

function Face(props) {
  const stoneRef = useRef<any | undefined>(undefined)
  const groupRef = useRef<any | undefined>(undefined)
  const isSmall = useIsSmall()

  // @ts-ignore
  const { nodes, materials, animations } = useGLTF(
    '/model/head-transformed.glb'
  )
  const { actions } = useAnimations(animations, groupRef)
  useEffect(() => {
    actions['Take 01']?.play()
  }, [actions])

  useThree(({ camera }) => {
    camera.position.x = 1.5
    camera.position.y = 0
    camera.position.z = 0
  })

  useFrame(({ camera }) => {
    camera.lookAt(0, 0, 0)
    camera.position.lerp(new Vector3(0, 0, 5), 0.045)
    camera.updateWorldMatrix(true, true)
  })
  return (
    <group
      ref={groupRef}
      {...props}
      dispose={null}
      scale={!isSmall ? 0.3 : 0.39}
      position={!isSmall ? [-0.1, -1.29, 0.61] : [0, -1.69, 0.61]}
      rotation={[-0.37, -1.27, 0]}
    >
      <pointLight position={[0, 16, 0]} intensity={1} color={'#f83ed6'} />
      <group position={[0, -0.25, 0]}>
        <ambientLight position={[0, 10, 0]} intensity={1} color={'#0d0c27'} />
        <directionalLight
          position={[-16.5, -16.3, -17.9]}
          intensity={2}
          color={'#ffffff'}
        />
        <directionalLight
          position={[-3.3, 7.4, 0]}
          intensity={2}
          color={'#3ef7d5'}
        />
        <directionalLight
          position={[-24, -25, -20]}
          intensity={0.5}
          color={'#6ffc68'}
        />
      </group>
      <group name="Sketchfab_Scene">
        <group
          name="Empty"
          position={[0.161, 4.808, -0.45]}
          rotation={[-1.675, 0.099, 0.002]}
          scale={0.892}
        >
          <group name="nimbus002">
            <mesh
              name="nimbus002_0"
              //@ts-ignore
              geometry={nodes.nimbus002_0.geometry}
              material={materials.Crown}
            />
          </group>
          <group name="nimbus001">
            <mesh
              name="nimbus001_0"
              //@ts-ignore
              geometry={nodes.nimbus001_0.geometry}
              material={materials.Crown}
            />
          </group>
        </group>

        <mesh
          ref={stoneRef}
          name="mentor_roman_retopo_0"
          //@ts-ignore
          geometry={nodes.mentor_roman_retopo_0.geometry}
          material={materials.Stone}
          position={[-0.266, 1.326, -0.12]}
          rotation={[-Math.PI / 2, 0, 1.3]}
        ></mesh>
        <mesh
          name="nimbus003_0"
          //@ts-ignore
          geometry={nodes.nimbus003_0.geometry}
          material={materials.Crown}
          position={[0.161, 4.808, -0.45]}
          rotation={[-1.675, 0.099, 0.002]}
          scale={0.892}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/model/head-transformed.glb')
export default Face
