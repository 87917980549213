'use client'
import { m, useInView, domAnimation, LazyMotion } from 'framer-motion'
import React from 'react'

const AnimatedTextWord = ({ text, className = '' }) => {
  const textRef = React.useRef(null)
  const isInView = useInView(textRef, { once: true })
  const words = text.split(' ')
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.05, delayChildren: 0.025 * i },
    }),
  }

  const child = {
    visible: {
      y: 0,
      transition: {
        type: 'tween',
        damping: 10,
        stiffness: 100,
        y: { duration: 0.35 },
        ease: [0.32, 0.23, 0.4, 0.6],
      },
    },
    hidden: {
      y: 40,
      transition: {
        type: 'tween',
        damping: 10,
        stiffness: 100,
        y: { duration: 0.35 },
        ease: [0.32, 0.23, 0.4, 0.6],
      },
    },
  }

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        className={`flex flex-wrap overflow-hidden leading-tight ${className}`}
        variants={container}
        initial="hidden"
        animate={isInView ? 'visible' : 'hidden'}
        ref={textRef}
      >
        {words.map((word: string, index: number) => (
          <m.span
            variants={child}
            className="mr-[0.25em] uppercase will-change-transform"
            key={index}
          >
            {word}
          </m.span>
        ))}
      </m.div>
    </LazyMotion>
  )
}

export default AnimatedTextWord
