'use client'
import styles from './styles.module.scss'
import { useStore } from 'lib/store'

import Link from 'next/link'

const NavigationItem = ({
  text,
  hasLeftBorder = false,
  href = '',
  onClick,
}) => (
  <Link href={`/works/${href}`} scroll className="w-1/2">
    <div
      className={`${
        styles['text-stroke']
      } relative h-full py-16 text-center text-[3rem] font-extrabold uppercase md:text-[4rem] lg:text-[8rem] ${
        hasLeftBorder ? 'border-l-2' : ''
      } `}
      onClick={onClick}
    >
      <div className={`${styles.bar} ${styles.left}`}></div>
      <div className={`${styles.bar} ${styles.top}`}></div>
      <div className={`${styles.bar} ${styles.right}`}></div>
      <div className={`${styles.bar} ${styles.bottom}`}></div>
      {text}
    </div>
  </Link>
)

const Navigation = () => {
  const index = useStore(
    ({ currentProject }: { currentProject: number }) => currentProject
  )
  const projects = useStore(({ projects }) => projects)
  const setCurrentProject = useStore(
    ({ setCurrentProject }) => setCurrentProject
  )
  let prev =
    index - 1 === projects.length
      ? 0
      : index - 1 < 0
      ? projects.length - 1
      : index - 1
  let next =
    index + 1 === projects.length
      ? 0
      : index + 1 < 0
      ? projects.length + 1
      : index + 1

  const handleClick = (index: number) => {
    setCurrentProject(index)
  }
  return (
    <div className={`${styles.navigation} flex w-full border-b-2`}>
      <NavigationItem
        text={'prev'}
        href={projects[prev].id}
        onClick={() => handleClick(prev)}
      />
      <NavigationItem
        text={'next'}
        hasLeftBorder
        href={projects[next].id}
        onClick={() => handleClick(next)}
      />
    </div>
  )
}

export default Navigation
