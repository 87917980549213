import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/components/animated-text-word/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/animated-title/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/description/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "/opt/buildhome/repo/components/dom/Layout.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/greeting/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/header/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/image-wrapper/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/indicator/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/marquee/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/motions/project-detail.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/motions/project-name.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/motions/text-reveal.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/navigation/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/preloader/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/scroll-animation/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/shared/CurveLoading.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/social/style.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/logo/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/text-dispere/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/wave-slider/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/webgl/face.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/webgl/lights.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/webgl/particles.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/webgl/rig.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/webgl/text.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/components/webgl/wave.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/node_modules/.pnpm/next@14.2.5_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1_sass@1.77.8/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/styles/globals.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/styles/work.module.scss");
