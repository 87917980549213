'use client'
import { Wave } from '@components/index'
import { useKeyboardControls } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { useStore } from 'lib/store'
import { useEffect, useState } from 'react'
import { TextureLoader } from 'three'
export default function WaveSlider() {
  const [lastIndex, setLastIndex] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [sub] = useKeyboardControls()
  const projects = useStore(({ projects }) => projects)
  const setCurrentProject = useStore(
    ({ setCurrentProject }) => setCurrentProject
  )
  const setShowIndicator = useStore(({ setShowIndicator }) => setShowIndicator)
  const projectIndex = useStore(({ currentProject }) => currentProject)
  const images = useLoader(TextureLoader, [
    ...projects.map((item) => item.thumbnail),
  ])

  function next(dir) {
    setLastIndex(currentIndex)
    setCurrentIndex((projects.length + dir) % projects.length)
    setCurrentProject((projects.length + dir) % projects.length)
  }
  useEffect(() => {
    next(projectIndex)
    if (projectIndex !== 0) {
      setShowIndicator(false)
    }
  }, [projectIndex])

  useEffect(() => {
    setCurrentProject(currentIndex)
    return sub((state) => {
      if (state.forward || state.right) {
        next(currentIndex + 1)
      } else if (state.backward || state.left) {
        next(currentIndex - 1)
      }
    })
  }, [lastIndex, currentIndex])

  return (
    <>
      <Wave
        {...{
          images,
          slug: projects[currentIndex]?.id,
          imageData: projects,
          currentIndex,
          lastIndex,
        }}
        next={next}
      />
    </>
  )
}
