import data from '../public/data/data.json'

export const colors = {
  hex: ['#da4468', '#98e6ff', '#4e54d2', '#e4878a', '#f5ca67'],
  rgb: [
    'rgb(255, 115, 148)',
    'rgb(152, 230, 255)',
    'rgb(228, 135, 138)',
    'rgb(166, 170, 255)',
    'rgb(245, 202, 103)',
  ],
}

export const routes = {
  works: {
    name: 'works',
    route: '/works',
  },
  about: {
    name: 'about',
    route: '/about',
  },
}

// Default position 0 0 5
export const cameraPos = {
  x: 0,
  y: 0,
  z: 5,
}

export const projects = data.map((item) => item.title.toLowerCase())

export const INTERVAL_TIME = 30

export const META_TITLE = 'Tam Pham - Portfolio ✨'
