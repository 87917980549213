const Path = (props) => (
  <path
    fill="transparent"
    strokeWidth="3"
    stroke="#fff"
    strokeLinecap="round"
    {...props}
  />
)

export const MenuToggle = ({ toggle }: any) => (
  <button
    type="button"
    role="button"
    onClick={toggle}
    name="menu"
    aria-label="toggle menu"
    className="fixed right-[4%] top-[0.5rem] z-[51] flex min-h-[40px] min-w-[40px] cursor-pointer items-center justify-center md:top-[2%] md:hidden"
  >
    <svg width="23" height="18" viewBox="0 0 23 18">
      <Path
        d="M 2 2.5 L 12 2.5"
        className="top"
        variants={{
          closed: { d: 'M 2 2.5 L 12 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path d="M 2 9.423 L 20 9.423" opacity="1" className="middle" />
      <Path
        d="M 2 16.346 L 20 16.346"
        className="bottom"
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </button>
)
