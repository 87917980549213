'use client'
import { Center, Text3D } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import { memo, useEffect, useRef, useState } from 'react'
function Text({ text }): React.ReactElement {
  const { width, height } = useThree((state) => state.viewport)
  const groupRef = useRef<any | null>(null!)
  const [positionY, setPositionY] = useState(0)
  const [scaleFactor, setScaleFactor] = useState(
    Math.min(Math.max(window.innerWidth / 1920, 0.4), 0.8)
  )

  useEffect(() => {
    window.innerWidth < 420
      ? setPositionY(0 - height / 2)
      : setPositionY(0 - height / 4)
    setScaleFactor(Math.min(Math.max(window.innerWidth / 1920, 0.4), 0.8))
  }, [width])

  return (
    <group scale={scaleFactor}>
      {/* @ts-ignore */}
      <Center
        top={false}
        right={false}
        bottom={true}
        left={true}
        position={[0.05, positionY, 0.5]}
      >
        <Text3D
          curveSegments={64}
          bevelEnabled
          bevelSize={0.01}
          bevelThickness={0.05}
          height={0.01}
          lineHeight={0.5}
          letterSpacing={0.01}
          size={1}
          scale={0.15}
          position-z={0.45}
          font="/font/Inter_Bold.json"
          ref={groupRef}
        >
          {text}
          <meshStandardMaterial color="white" />
        </Text3D>
      </Center>
    </group>
  )
}

export default memo(Text)
