import Link from 'next/link'
import SETTING from '@/config/settings.json'
import { MenuToggle } from '@components/header/menu-toggle'
import { m, stagger, useAnimate, LazyMotion, domAnimation } from 'framer-motion'
import { useEffect, useState } from 'react'

const useMenuAnimation = (isOpen) => {
  const [scope, animate] = useAnimate()

  useEffect(() => {
    const menuAnimations = isOpen
      ? [
          [
            '#menu',
            {
              display: 'flex',
            },
          ],
          [
            '#wrapper',
            {
              height: '100dvh',
              display: 'block',
              padding: '5%',
            },
            {
              ease: [0.08, 0.65, 0.53, 0.86],
            },
          ],
          ['span', { width: '100%' }],
          [
            'li',
            { transform: 'translateX(0px)', opacity: 1 },
            { delay: stagger(0.02), at: '-0.1' },
          ],
        ]
      : [
          ['span', { width: 0 }],
          [
            'li',
            { transform: 'translateX(50px)', opacity: 0 },
            { delay: stagger(0.02, { from: 'last' }), at: '<' },
          ],
          [
            '#wrapper',
            {
              height: '0',
              width: '0',
              display: 'none',
              padding: 0,
            },
          ],
          ['#menu', { display: 'none' }],
        ]

    animate([
      [
        'path.top',
        { d: isOpen ? 'M 3 16.5 L 17 2.5' : 'M 2 2.5 L 12 2.5' },
        { at: '<' },
      ],
      ['path.middle', { opacity: isOpen ? 0 : 1 }, { at: '<' }],
      [
        'path.bottom',
        { d: isOpen ? 'M 3 2.5 L 17 16.346' : 'M 2 16.346 L 12 16.346' },
        { at: '<' },
      ],
      // @ts-ignore
      ...menuAnimations,
    ])
  }, [isOpen])

  return scope
}
const MobileNavLink = ({
  href,
  title,
  type = false,
  toggle,
  isBlank = false,
}) => {
  const isSmall = type
    ? 'text-xl h-[50px]'
    : 'text-5xl font-bold leading-[5rem]'

  return (
    <li
      className={`list-none uppercase tracking-wider text-white ${isSmall}`}
      onClick={toggle}
    >
      <Link
        href={href}
        scroll
        target={isBlank ? '_blank' : '_self'}
        rel="noopener noreferrer"
      >
        {title}
      </Link>
    </li>
  )
}

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false)

  const scope = useMenuAnimation(isOpen)
  const sidebar = {
    open: {
      clipPath: `circle(100%)`,
      opacity: 1,
    },
    closed: {
      clipPath: 'circle(4% at 91% 6%)',
      opacity: 0,
      transition: {
        delay: 0.65,
      },
    },
  }
  const mobileNavLinks = [
    {
      title: 'home',
      href: '/',
    },
    {
      title: 'works',
      href: '/works',
    },
    {
      title: 'about',
      href: '/about',
    },
  ]
  const contactNavLinks = [
    {
      title: SETTING.SOCIAL.LINKEDIN.LABEL,
      href: SETTING.SOCIAL.LINKEDIN.LINK,
    },
    {
      title: SETTING.SOCIAL.GITHUB.LABEL,
      href: SETTING.SOCIAL.GITHUB.LINK,
    },
    {
      title: 'email',
      href: SETTING.SOCIAL.EMAIL.LINK,
    },
  ]
  return (
    <>
      <LazyMotion features={domAnimation}>
        <m.div
          className="fixed inset-0 -z-[1] h-[100%] w-[100%] bg-black"
          variants={sidebar}
          animate={isOpen ? 'open' : 'closed'}
        ></m.div>
      </LazyMotion>
      <div
        ref={scope}
        className="fixed left-0 top-[0%] flex w-full flex-col md:hidden"
      >
        <MenuToggle toggle={() => setIsOpen(!isOpen)} />
        <div id="wrapper" className={`hidden h-full overflow-hidden`}>
          <div className="mx-auto mt-[10%]">
            {mobileNavLinks.map((link, key) => (
              <MobileNavLink
                key={key}
                toggle={() => setIsOpen(false)}
                title={link.title}
                href={link.href}
              />
            ))}
          </div>

          <span
            className="mx-auto inline-block "
            style={{ backgroundColor: '#5f5f5f9e', height: '1px' }}
          ></span>

          <div className="mt-[5%]">
            {contactNavLinks.map((link, key) => (
              <MobileNavLink
                key={key}
                toggle={() => setIsOpen(false)}
                title={link.title}
                href={link.href}
                isBlank
                type={true}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}
