'use client'
import { useIsSmall } from '@/utils'
import {
  m,
  useMotionValueEvent,
  useScroll,
  LazyMotion,
  domAnimation,
} from 'framer-motion'
import React from 'react'
export default function ProjectDetailTitle({ title = 'Diab', slug }) {
  const { scrollY } = useScroll()
  const [isAnimate, setIsAnimate] = React.useState(false)
  const isSmall = useIsSmall()
  const color = `from-${slug}`
  useMotionValueEvent(scrollY, 'change', (latest) => {
    latest > 80 ? setIsAnimate(true) : setIsAnimate(false)
  })
  const titleVariant = {
    open: isSmall
      ? {
          y: -180,
          lineHeight: '3rem',
          fontSize: '3rem',
          fontWeight: 700,
          letterSpacing: '0em',
          opacity: 1,
          transition: {
            opacity: { duration: 0.6 },
            ease: [0.2, 0.65, 0.3, 0.9],
          },
        }
      : {
          y: -160,
          lineHeight: '3rem',
          fontSize: '2rem',
          fontWeight: 700,
          letterSpacing: '0em',
          opacity: 1,
          transition: {
            opacity: { duration: 0.6 },
            ease: [0.2, 0.65, 0.3, 0.9],
          },
        },
    closed: isSmall
      ? {
          y: -60,
          opacity: 1,
          fontWeight: 700,
          fontSize: '16rem',
          lineHeight: '12.5rem',
          letterSpacing: '0.025em',
          transition: {
            opacity: { duration: 0.6 },
            y: { duration: 0.4 },
            ease: [0.2, 0.65, 0.3, 0.9],
          },
        }
      : {
          y: -90,
          opacity: 1,
          fontWeight: 700,
          fontSize: '5rem',
          lineHeight: '5.5rem',
          letterSpacing: '0.025em',
          transition: {
            opacity: { duration: 0.6 },
            y: { duration: 0.4 },
            ease: [0.2, 0.65, 0.3, 0.9],
          },
        },
  }
  return (
    <LazyMotion features={domAnimation}>
      <m.section
        className={`project-title fixed z-[1] flex h-[120px] w-full items-center justify-center ${
          isAnimate && `bg-gradient-to-b ${color}`
        }`}
        animate={isAnimate ? 'open' : 'closed'}
        variants={titleVariant}
      >
        <h1
          className="flex items-center justify-center text-center uppercase"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          {title}
        </h1>
      </m.section>
    </LazyMotion>
  )
}
