'use client'
import { gsap, Expo } from 'gsap/gsap-core'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { useEffect, useState } from 'react'
import { IBM_Plex_Mono } from 'next/font/google'
import { useStore } from '@/lib/store'
gsap.registerPlugin(CSSRulePlugin)
const mono = IBM_Plex_Mono({
  subsets: ['latin'],
  weight: ['500'],
  display: 'swap',
})
export default function PreLoader() {
  const setPageReady = useStore(({ setPageReady }) => setPageReady)
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    const count = setInterval(() => {
      //@ts-ignore
      setCounter((counter) =>
        counter < 100
          ? counter + 1
          : (clearInterval(count), setCounter(100), reveal())
      )
    }, 25)
    return () => {
      clearInterval(count)
    }
  }, [setPageReady])

  const reveal = () => {
    gsap
      .timeline({
        onStart: () => {
          setPageReady(false)
        },
        onComplete: () => {
          setTimeout(() => {
            setPageReady(true)
          })
        },
      })
      .to('.follow', {
        width: '100%',
        duration: 0.5,
        delay: 0.3,
        ease: Expo.easeInOut,
      })
      .to('.hide', {
        opacity: 0,
        duration: 0.3,
      })
      .to('.hide', {
        display: 'none',
        duration: 0.3,
      })
      .to('.follow', {
        height: '100%',
        duration: 0.4,
        delay: 0.2,
        ease: Expo.easeInOut,
      })
      .to('.preloader', {
        display: 'none',
        ease: Expo.easeInOut,
      })
  }
  return (
    <div
      className={`preloader fixed left-0 top-0 z-[9999] w-screen bg-white h-dynamic ${mono.className}`}
    >
      <div className="loading absolute top-0 flex h-full w-full items-center justify-center">
        <div className="follow absolute left-0 z-[2] h-[1px] w-0 bg-black" />
        <div
          className="hide progress-bar absolute left-0 z-[1] h-[1px] w-0 bg-black transition-all duration-300 ease-in-out will-change-transform"
          style={{ width: counter + '%' }}
        />
        <div className="count hide text-bold absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-6xl text-black md:text-9xl">
          {counter}%
        </div>
      </div>
    </div>
  )
}
