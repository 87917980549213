'use client'
import { useFrame } from '@react-three/fiber'
import { useRef } from 'react'
import { Group, MathUtils, Vector3 } from 'three'

interface Props {
  children: React.ReactNode
  speed?: number
  float?: boolean
  isCamera?: boolean
}
const Rig: React.FC<Props> = ({
  children,
  speed = 0.025,
  float = false,
  isCamera = false,
}) => {
  const ref = useRef<Group | null>(null)
  const vec = new Vector3()
  const camVec = new Vector3()
  useFrame(({ mouse, camera }) => {
    if (ref.current === null) return
    if (isCamera) {
      camVec.set((mouse.x * Math.PI) / 2, (mouse.y * Math.PI) / 2, 5)
      camera.position.lerp(camVec, 0.005)
      camera.lookAt(0, 0, 0)
      return
    }
    if (float) {
      ref.current?.position.lerp(vec.set(mouse.x / 25, mouse.y / 40, 0), speed)
    }

    // prevent object rotation on mouse move
    ref.current.rotation.x = MathUtils.lerp(
      ref.current.rotation.x,
      (mouse.y * Math.PI) / 15,
      speed
    )
    ref.current.rotation.y = MathUtils.lerp(
      ref.current.rotation.x,
      -mouse.x * Math.PI * 3,
      speed
    )
  })

  return <group ref={ref}>{children}</group>
}

export default Rig
