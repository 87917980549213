'use client'
import { Vector3 } from 'three'
import { useRef } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { SpotLight } from '@react-three/drei'
function Light({ vec = new Vector3(), ...props }) {
  const light = useRef<any | null>(null!)
  const viewport = useThree((state) => state.viewport)
  useFrame((state) => {
    light.current.target.position.lerp(
      vec.set(
        (state.mouse.x * viewport.getCurrentViewport().width) / 2,
        (state.mouse.y * viewport.getCurrentViewport().height) / 2,
        0
      ),
      0.055
    )
    light.current.target.updateMatrixWorld()
  })
  return (
    <SpotLight
      castShadow
      ref={light}
      penumbra={1}
      distance={4.8}
      angle={-0.2}
      attenuation={2.4}
      anglePower={7.0}
      intensity={1}
      position={[-1.1, 1, 1.3]}
      {...props}
    />
  )
}

export default Light
