'use client'
import {
  m,
  useScroll,
  useTransform,
  domAnimation,
  LazyMotion,
} from 'framer-motion'
import React, { useRef } from 'react'

export default function Paragraph({ text, className = '' }) {
  const container = useRef(null)
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start 0.8', 'start 0.25'],
  })

  const words = text.split(' ')
  return (
    <div
      ref={container}
      className={`text-xl leading-none text-white md:text-3xl ${className}`}
    >
      {words.map((word, i) => {
        const start = i / words.length
        const end = start + 1 / words.length
        return (
          <Word key={i} progress={scrollYProgress} range={[start, end]}>
            {word}
          </Word>
        )
      })}
    </div>
  )
}

const Word = ({ children, progress, range }) => {
  const opacity = useTransform(progress, range, [0, 1])
  return (
    <LazyMotion features={domAnimation}>
      <span className="relative mr-[0.25em] mt-[0.25em] inline-block w-fit">
        <span className="absolute opacity-10">{children}</span>
        <m.span style={{ opacity: opacity }}>{children}</m.span>
      </span>
    </LazyMotion>
  )
}
