'use client'
import { useRef } from 'react'
import { motion, useScroll, useTransform, useSpring } from 'framer-motion'
import Image from 'next/image'
import styles from './styles.module.scss'

interface ScrollAnimationProps {
  src: any
}
const ScrollAnimation = ({ src }: ScrollAnimationProps) => {
  const containerRef = useRef(null)

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'end end'],
  })
  const springYProgress = useSpring(scrollYProgress, {
    stiffness: 20,
    damping: 10,
    restDelta: 0.0015,
  })
  const bottomShadowValue = useTransform(
    springYProgress,
    [0, 1],
    ['-100%', '0%']
  )
  const imageValue = useTransform(springYProgress, [0, 1], ['-100%', '0%'])
  const topShadowValue = useTransform(springYProgress, [0, 1], ['-25%', '100%'])

  return (
    <section className={styles['scroll-container']} ref={containerRef}>
      <div className={styles.copy}>
        <h2>Lorem ipsum dolor sit amet</h2>
      </div>
      <div className={styles['image-container']}>
        <motion.div
          viewport={{ once: true }}
          className="img-inner"
          style={{ translateX: imageValue }}
        >
          <motion.div
            className={styles['bottom-shadow']}
            style={{ translateX: bottomShadowValue }}
          />
          <Image
            className="relative z-[1] h-auto w-full max-w-[700px] "
            src={src}
            alt="a green plant"
          />
          <motion.div
            className={styles['top-shadow']}
            style={{ translateX: topShadowValue }}
          />
        </motion.div>
      </div>
    </section>
  )
}

export default ScrollAnimation
