'use client'
import Image from 'next/image'
import { useEffect, useRef } from 'react'
import { gsap, Power4 } from 'gsap'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import { useStore } from 'lib/store'
import { Project } from '@/app/core/interface'
gsap.registerPlugin(CSSRulePlugin, ScrollTrigger)

interface ImageWrapperProps {
  src: any
  bg?: string | undefined
  isPriority?: boolean
}
const ImageWrapper = ({
  bg,
  src = '',
  isPriority = false,
}: ImageWrapperProps) => {
  let container = useRef(null)
  let image = useRef(null)
  const mask = useRef(null)
  const tl = gsap.timeline()
  const projects = useStore((state: { projects: Project[] }) => state.projects)
  const currentProject = useStore(
    (state: { currentProject: any }) => state.currentProject
  )
  useEffect(() => {
    const lenis = new Lenis({ wrapper: document.body })
    ScrollTrigger.create({
      start: 0,
      end: 'max',
      onUpdate: () => {
        onImageReveal()
      },
    })
    const raf = (time) => {
      lenis.raf(time)
      ScrollTrigger.update()
      requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)
    onImageReveal()

    return () => {
      ScrollTrigger.killAll()
    }
  }, [])

  const onImageReveal = () => {
    // @ts-ignore
    if (
      container.current &&
      ScrollTrigger?.isInViewport(container.current, 0.2)
    ) {
      tl.to(container.current, { duration: 0, visibility: 'visible' })
        .to(mask.current, {
          duration: 1,
          width: '0%',
          ease: Power4.easeInOut,
        })
        .to(image.current, {
          duration: 1,
          scale: 1,
          ease: Power4.easeInOut,
          delay: -0.75,
        })
    }
  }

  return (
    <figure
      ref={container}
      className={`image-container relative aspect-video h-auto w-full rounded-2xl ${
        bg && bg
      }`}
    >
      <span
        className="mask absolute right-0 top-0 z-[1] h-full w-full"
        style={{
          backgroundColor: projects[currentProject].detail.color.background,
        }}
        ref={mask}
      ></span>
      <Image
        ref={image}
        priority={isPriority}
        src={src}
        alt="section image"
        quality={70}
        fill
        className={`scale-125 rounded-2xl`}
      />
    </figure>
  )
}

export default ImageWrapper
