'use client'
import { useState } from 'react'
import { m, LazyMotion, domAnimation } from 'framer-motion'
import { disperse } from './animation'

export default function TextDipserse({ children, setBackground, onClick }) {
  const [isAnimated, setIsAnimated] = useState(false)

  const getChars = (element: any) => {
    let chars: any[] = []
    const word = element.props.children || []
    word.split('').forEach((char, i) => {
      chars.push(
        <m.span
          custom={i}
          variants={disperse}
          animate={isAnimated ? 'open' : 'closed'}
          key={char + i}
        >
          {char}
        </m.span>
      )
    })
    return chars
  }
  const manageMouseEnter = (): void => {
    setBackground(true)
    setIsAnimated(true)
  }
  const manageMouseLeave = (): void => {
    setBackground(false)
    setIsAnimated(false)
  }

  return (
    <LazyMotion features={domAnimation}>
      <div
        onClick={onClick}
        onMouseEnter={() => {
          manageMouseEnter()
        }}
        onMouseLeave={() => {
          manageMouseLeave()
        }}
        className="introLine cursor-pointer"
      >
        {getChars(children)}
      </div>
    </LazyMotion>
  )
}
