import { shaderMaterial } from '@react-three/drei'
import { extend } from '@react-three/fiber'
import { Texture, Color } from 'three'
import { fragment, vertex } from '../glsl/wave'

// Add types to ThreeElements elements so primitives pick up on it
interface IWave {
  uTime: number
  uProgress: number
}
const WaveShaderMaterial = shaderMaterial(
  // Uniform
  {
    uTime: 0,
    uColor: new Color(0.0, 0.0, 0.0),
    uTextureOne: new Texture(),
    uTextureTwo: new Texture(),
    uTextureMap: new Texture(),
    uProgress: 0,
    patternScale: 35.0,
  },
  // Vertex Shader
  vertex,
  // Fragment Shader
  fragment
)
export { WaveShaderMaterial as waveCustomMaterial }
export type WaveShaderMaterial = {
  uTime?: { value: number }
  uProgress?: { value: number }
  uTextureOne?: Texture
  uTextureTwo?: Texture
  uTextureMap?: any
} & JSX.IntrinsicElements['shaderMaterial']

extend({ WaveShaderMaterial })

declare global {
  namespace JSX {
    interface IntrinsicElements {
      waveShaderMaterial: WaveShaderMaterial
    }
  }
}
