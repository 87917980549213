'use client'
import React, { useRef, useEffect } from 'react'
import { gsap } from 'gsap/gsap-core'
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin)

const AnimatedWrapper = ({ className = '', children }) => {
  const wrapperRef = useRef(null)

  useEffect(() => {
    const wrapper = wrapperRef.current
    if (wrapper) {
      // @ts-ignore
      const elements = wrapper.children
      gsap.to(wrapper, { opacity: 1 })
      gsap.set(elements, { opacity: 0 })
      gsap.to(elements, {
        delay: 0.4,
        duration: 1,
        opacity: 1,
        stagger: 0.2,
        ease: 'power4.out',
      })
    }
  }, [])

  return (
    <div className={`${className} opacity-0 `} ref={wrapperRef}>
      {children}
    </div>
  )
}

export default AnimatedWrapper
