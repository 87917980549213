'use client'
import { useRef, useEffect } from 'react'
import { usePathname } from 'next/navigation'

export default function CurveLoading() {
  const loader = useRef<HTMLDivElement | null>(null)
  const path = useRef<HTMLDivElement>(null)
  const initialCurve = 200
  const duration = 600
  const routeName = usePathname()
  let start
  useEffect(() => {
    setPath(initialCurve)
    requestAnimationFrame(animate)
  }, [routeName])

  const animate = (timestamp: number) => {
    if (start === undefined) {
      start = timestamp
    }
    const elapsed = timestamp - start

    const newCurve = easeOutQuad(elapsed, initialCurve, -200, duration)
    setPath(newCurve)
    if (loader.current !== null) {
      loader.current.style.zIndex = '0'
      loader.current.style.transform = `translateY(${easeOutQuad(
        elapsed,
        0,
        -loaderHeight() - 100,
        duration
      )}px)`
      if (elapsed < duration) {
        requestAnimationFrame(animate)
        loader.current.style.zIndex = '100'
      }
    }
  }

  const easeOutQuad = (
    time: number,
    start: number,
    end: number,
    duration: number
  ) => {
    return -end * (time /= duration) * (time - 2) + start
  }

  const loaderHeight = () => {
    if (loader.current === null) return 0
    const loaderBounds = loader.current.getBoundingClientRect()
    return loaderBounds.height
  }
  const setPath = (curve: number) => {
    const width = window.innerWidth
    const height = loaderHeight()
    if (path.current !== null) {
      path.current.setAttributeNS(
        null,
        'd',
        `M0 0
        L${width} 0
        L${width} ${height}
        Q${width / 2} ${height - curve} 0 ${height}
        L0 0`
      )
    }
  }

  return (
    <div
      ref={loader}
      className="fixed top-0 h-[calc(100dvh+200px)] w-full will-change-transform"
    >
      <svg className="h-full w-full">
        {/* @ts-ignore */}
        <path ref={path} className="fill-white stroke-white stroke-1"></path>
      </svg>
    </div>
  )
}
