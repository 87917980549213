import { useMediaQuery } from '@/hooks/useMediaQuery'
export const MathEx = {
  clamp: (value: number, min: number, max: number): number => {
    return Math.min(Math.max(value, min), max)
  },
  radians: (degrees: number) => {
    return (degrees * Math.PI) / 180
  },
}

export const useIsSmall = () => useMediaQuery('(min-width: 480px)')
export const useIsMedium = () => useMediaQuery('(max-width: 768px)')
