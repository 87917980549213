'use client'
import { INTERVAL_TIME, routes } from '@/constant'
import Navbar from '@components/header/nav-bar'
import { Greeting, Logo, TextReveal } from '@components/index'
import Link from 'next/link'
import { useStore } from '@/lib/store'
import { usePathname } from 'next/navigation'
import { m, LazyMotion, domAnimation } from 'framer-motion'
export default function Header(props) {
  const isPageReady = useStore(({ isReady }) => isReady)
  const menus = [routes.works, routes.about]
  const pathname = usePathname()

  const renderHeaderMenu = (route: string, name: string) => (
    <Link
      href={route}
      className={`menu-item relative flex cursor-pointer items-center`}
      key={name}
    >
      {pathname.includes(route) ? (
        <LazyMotion features={domAnimation}>
          <m.div className="underline" layoutId="underline" />
        </LazyMotion>
      ) : null}
      <Greeting
        text={name}
        intervalTime={INTERVAL_TIME}
        className={`about relative mx-2 cursor-pointer overflow-hidden text-lg uppercase leading-none tracking-widest md:text-2xl ${
          pathname.includes(route) ? 'active' : null
        }`}
      />
    </Link>
  )

  const headerMenus = (
    <div className="relative hidden h-[40px] min-w-[90px] select-none overflow-hidden rounded-3xl  text-center  md:flex">
      {menus.map((menu) => renderHeaderMenu(menu.route, menu.name))}
    </div>
  )

  return (
    isPageReady && (
      <header {...props}>
        <TextReveal>
          <nav className="relative flex items-center justify-between md:w-full">
            <Logo />
            {headerMenus}
            <Navbar />
          </nav>
        </TextReveal>
      </header>
    )
  )
}
