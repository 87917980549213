'use client'

import { m, useInView, LazyMotion, domAnimation } from 'framer-motion'
import { useRef } from 'react'
type AnimatedTitleProps = {
  text?: string
  isAnimateChar?: boolean
  children?: React.ReactChild
  [x: string]: any
}

export default function AnimatedTitle({
  text = `Hi! I'm TAMPHAM`,
  isAnimateChar = false,
  children,
  ...props
}: AnimatedTitleProps) {
  const words = text.split(' ')
  const animateTitleRef = useRef<any>(true)
  const isInView = useInView(animateTitleRef, { once: true })

  const wordVariants = {
    open: {
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.01,
      },
    },
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  }

  const charVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        opacity: { duration: 1 },
        y: { stiffness: 1000, velocity: -1000, duration: 0.3 },
        color: { duration: 3, repeat: Infinity },
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
    closed: {
      y: 20,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  }
  return (
    <LazyMotion features={domAnimation}>
      {isAnimateChar ? (
        <div ref={animateTitleRef} className="inline-block min-h-0">
          {words.map((word, wordIndex) => (
            <m.h2
              className="mb-4"
              {...props}
              initial={false}
              animate={isInView ? 'open' : 'closed'}
              key={wordIndex}
              variants={wordVariants}
              transition={{
                delayChildren: wordIndex * 0.25,
                staggerChildren: 0.05,
              }}
            >
              {Array.from(word).map((char, charIndex) => (
                <m.span
                  key={charIndex}
                  variants={charVariants}
                  className={`inline-block`}
                >
                  {char}
                </m.span>
              ))}
            </m.h2>
          ))}
        </div>
      ) : (
        <m.div
          {...props}
          ref={animateTitleRef}
          animate={isInView ? 'open' : 'closed'}
          initial={false}
          variants={charVariants}
          className={`mb-2 mr-1 block ${props.className}`}
        >
          {children}
        </m.div>
      )}
    </LazyMotion>
  )
}
